var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form__line form__line--minmargin"},[_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"form__line--input"},[_c('div',{staticClass:"form__line--title"},[_vm._v(_vm._s(_vm.$t('site.registration.last_name')))]),_c('div',{staticClass:"form__block--input",class:[
						_vm.errorMessage.messages && _vm.errorMessage.messages.last_name ? 'input--error' : '',
					]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.last_name),expression:"last_name"}],staticClass:"input-linck",attrs:{"type":"text","required":"","name":"last_name","value":"","placeholder":_vm.$t('site.registration.last_name')},domProps:{"value":(_vm.last_name)},on:{"input":function($event){if($event.target.composing)return;_vm.last_name=$event.target.value}}})]),(_vm.errorMessage.messages && _vm.errorMessage.messages.last_name)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.last_name),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])]),_c('div',{staticClass:"form__line form__line--minmargin"},[_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"form__line--input"},[_c('div',{staticClass:"form__line--title"},[_vm._v(_vm._s(_vm.$t('site.registration.name')))]),_c('div',{staticClass:"form__block--input",class:[
						_vm.errorMessage.messages && _vm.errorMessage.messages.first_name ? 'input--error' : '',
					]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.first_name),expression:"first_name"}],staticClass:"input-linck",attrs:{"type":"text","required":"","name":"first_name","value":"","placeholder":_vm.$t('site.registration.name')},domProps:{"value":(_vm.first_name)},on:{"input":function($event){if($event.target.composing)return;_vm.first_name=$event.target.value}}})]),(_vm.errorMessage.messages && _vm.errorMessage.messages.first_name)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.first_name),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])]),_c('div',{staticClass:"form__line form__line--minmargin"},[_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"form__line--input"},[_c('div',{staticClass:"form__line--title"},[_vm._v(_vm._s(_vm.$t('site.registration.middle_name')))]),_c('div',{staticClass:"form__block--input",class:[
						_vm.errorMessage.messages && _vm.errorMessage.messages.middle_name ? 'input--error' : '',
					]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.middle_name),expression:"middle_name"}],staticClass:"input-linck",attrs:{"type":"text","required":"","name":"middle_name","value":"","placeholder":_vm.$t('site.registration.middle_name')},domProps:{"value":(_vm.middle_name)},on:{"input":function($event){if($event.target.composing)return;_vm.middle_name=$event.target.value}}})]),(_vm.errorMessage.messages && _vm.errorMessage.messages.middle_name)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.middle_name),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])]),_c('div',{staticClass:"form__line form__line--minmargin"},[_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"form__line--input"},[_c('div',{staticClass:"form__line--title"},[_vm._v(_vm._s(_vm.$t('site.registration.name_company')))]),_c('div',{staticClass:"form__block--input",class:[
						_vm.errorMessage.messages && _vm.errorMessage.messages.name_company ? 'input--error' : '',
					]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name_company),expression:"name_company"}],staticClass:"input-linck",attrs:{"type":"text","required":"","name":"name_company","value":"","placeholder":_vm.$t('site.registration.name_company')},domProps:{"value":(_vm.name_company)},on:{"input":function($event){if($event.target.composing)return;_vm.name_company=$event.target.value}}})]),(_vm.errorMessage.messages && _vm.errorMessage.messages.name_company)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.name_company),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])]),_c('div',{staticClass:"form__line form__line--minmargin"},[_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"form__line--input"},[_c('div',{staticClass:"form__line--title"},[_vm._v(_vm._s(_vm.$t('components.region')))]),_c('div',{staticClass:"form__block--input",class:[
						_vm.errorMessage.messages && _vm.errorMessage.messages.region_id ? 'input--error' : '',
					]},[_c('region',{attrs:{"class-list":['input-linck'],"modelValue":_vm.region_id,"name":'region_id',"required":true,"multiple":true,"placeholder":_vm.$t('components.region')},on:{"update:modelValue":function($event){_vm.region_id = $event}}})],1),(_vm.errorMessage.messages && _vm.errorMessage.messages.region_id)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.region_id),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])]),_c('div',{staticClass:"form__line form__line--minmargin"},[_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"form__line--input"},[_c('div',{staticClass:"form__line--title"},[_vm._v(_vm._s(_vm.$t('site.registration.email')))]),_c('div',{staticClass:"form__block--input",class:[_vm.errorMessage.messages && _vm.errorMessage.messages.email ? 'input--error' : '']},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"input-linck",attrs:{"type":"email","required":"","name":"email","value":"","placeholder":_vm.$t('site.registration.email')},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),(_vm.errorMessage.messages && _vm.errorMessage.messages.email)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.email),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])]),_c('div',{staticClass:"form__line"},[_c('div',{staticClass:"form__line--right"},[_c('button',{staticClass:"input-btn form__line--btn"},[_vm._v(" "+_vm._s(_vm.$t('button.save'))+" ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[(_vm.message.text)?_c('div',{staticClass:"form__block--line form__messages mb-5",class:{
					'form__messages--error': _vm.message.status != 200,
					'form__messages--success': _vm.message.status == 200,
				}},[_c('label',{staticClass:"form__block--input"},[_c('span',{staticClass:"form__block--title"},[_vm._v(" "+_vm._s(_vm.message.text)+" ")])])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }